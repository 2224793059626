@use '../../styles/mixins/breakpoints' as *;

.root {
  max-width: 25rem;

  @include breakpoint('sm') {
    max-width: 100%;
    width: 100%;
  }

  :global(.mantine-Input-wrapper) {
    margin-top: calc(var(--mantine-spacing-xs) / 2);
    margin-bottom: calc(var(--mantine-spacing-xs) / 2);
  }

  :global(.mantine-Radio-root) {
    margin-top: calc(var(--mantine-spacing-xs) / 2);
    margin-bottom: calc(var(--mantine-spacing-xs) / 2);
    padding: 0;
  }

  :global(.mantine-InputWrapper-label) {
    font-size: var(--mantine-font-size-sm);
  }

  :global(.mantine-Input-input) {
    border-color: var(--mantine-color-gray-5);

    &:focus {
      border-color: var(--mantine-color-brand-5);
      outline: solid 1px var(--mantine-color-brand-5);
    }

    &:disabled {
      color: var(--mantine-color-gray-7);
      opacity: 1;
    }

    &.isWarning {
      color: var(--mantine-color-clay-7);
    }
  }

  :global(.mantine-InputWrapper-description) {
    color: var(--mantine-color-gray-7);
    font-size: var(--mantine-font-size-sm);
  }

  :global(.mantine-InputWrapper-error) {
    color: var(--mantine-color-red-7);
    font-size: var(--mantine-font-size-sm);
    margin-bottom: calc(var(--mantine-spacing-xs) / 2);

    &.isWarning {
      color: var(--mantine-color-clay-7);
    }
  }

  :global(.mantine-Select-item) {
    &:hover,
    &[data-hovered] {
      background-color: var(--mantine-color-gray-4);
      color: var(--mantine-color-gray-9);
    }
    &[data-selected] {
      font-weight: var(--mantine-font-weight-bold);
      &:hover,
      &[data-hovered] {
        background-color: var(--mantine-color-brand-8);
        color: var(--mantine-color-white);
      }
    }
  }

  :global(.mantine-MultiSelect-value) {
    border: 1px solid var(--mantine-color-gray-7);
  }
}
