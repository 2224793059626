.group {
  gap: calc(var(--mantine-spacing-xs) / 2);

  .button {
    transition: all 0.25s;
    background-color: var(--mantine-color-white);
    color: var(--mantine-color-gray-7);
    font-weight: var(--mantine-font-weight-bold);
    font-size: var(--mantine-font-size-lg);
    border-radius: 1.5rem;
    transform: none;

    > div {
      align-items: flex-start;
      justify-content: left;
    }

    &:hover {
      background-color: var(--mantine-color-gray-3);
      color: var(--mantine-color-gray-8);
    }
    &[data-active="true"] {
      background: var(--mantine-brand-gradient);
      color: var(--mantine-color-white);
    }
  }
}
