@use '@agtuary/ui/styles/mixins/breakpoints' as *;

.header {
  --header-logo-size: 2rem;
  z-index: 200;
  background: linear-gradient(
    162deg,
    var(--mantine-color-gray-2) 50%,
    var(--mantine-color-gray-3) 100%
  );

  border: none;

  overflow: visible;

  h1 {
    font-size: var(--mantine-font-size-lg);
    font-weight: var(--mantine-font-weight-bold);
  }

  .group {
    height: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: nowrap;

    // Hide scrollbar
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;

    .section {
      height: 100%;
      display: flex;
      justify-content: center;

      &:first-of-type {
        justify-content: flex-start;
      }
      &:last-of-type {
        justify-content: flex-end;
      }
      min-width: fit-content;

      .sectionContents {
        height: 100%;
        display: inline-block;
        width: fit-content;

        .logoBreadcrumbGroup {
          height: 100%;
          padding-left: 0.75rem;

          .breadcrumbContainer {
            height: 100%;

            @include breakpoint('sm') {
              display: none;
            }

            .breadcrumb {
              line-height: var(--mantine-header-height);
              color: var(--mantine-color-gray-1);
              max-width: 160px;

              @include breakpoint('lg') {
                max-width: 100px;
              }
            }

            .breadcrumb:first-of-type,
            :global(.mantine-Breadcrumbs-separator) {
              @include breakpoint('md') {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

.progress {
  position: absolute;
  top: 100%;
  width: 100%;
  > div {
    background-color: inherit;
  }
}
