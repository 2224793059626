.container {
  position: relative;
  object-fit: contain;
  overflow: hidden;
  height: var(--header-logo-size);
  
  svg {
    height: 100%;
  }
}
