.sortableTableHeader {
  cursor: pointer;
  user-select: none;
}

.tableRow {
  cursor: pointer;
}

.disabledTableRow {
  background-color: transparent;
  color: #bfbfbf;

  & td {
    background-color: transparent;
  }

  &:hover {
    background-color: transparent;
  }
}
