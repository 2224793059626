@use 'sass:map';

// ! Ensure these match packages/ui/theme/breakpoints.ts
// We should look at postCSS when migrating to Mantine v7
// https://mantine.dev/styles/responsive/#breakpoints-variables-in-css-modules
$breakpoints: (
  xxxl: 150em,
  xxl: 110em,
  xl: 88em,
  lg: 75em,
  md: 62em,
  sm: 48em,
  xs: 36em,
);

@mixin breakpoint($point) {
  @media screen and (max-width: map.get($breakpoints, $point)) {
    @content;
  }
}
