.CurrentSection {
  background: var(--mantine-brand-gradient);
  border-radius: 1.5rem;
  padding: calc(var(--mantine-spacing-xs) / 2);
  padding-right: var(--mantine-spacing-md);
  gap: calc(var(--mantine-spacing-xs) / 2);
}

.CurrentSectionNoMenu {
  @extend .CurrentSection;
  padding-left: var(--mantine-spacing-md);
}

.MenuToggleButton {
  background: none;
  &:hover {
    background: none;
  }
}

.dropdown {
  background: var(--mantine-color-white);
  padding: var(--mantine-spacing-xs);
  border-radius: var(--mantine-spacing-xs);
  box-shadow: var(--mantine-shadow-xl);
}

.icon {
  width: 1rem;
  height: 1rem;
  color: var(--mantine-color-white);
}

.button {
  transition: all 0.25s;
  background-color: transparent;
  color: var(--mantine-color-white) !important;
  font-weight: var(--mantine-font-weight-bold);
  font-size: var(--mantine-font-size-lg);
  border-radius: 24px;
  transform: none;
  width: fit-content;
  height: auto;
  padding: 0px;

  > div > span {
    overflow: visible;
  }

  &:hover {
    background-color: transparent;
    text-decoration: underline;
  }
}
