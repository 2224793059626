.group {
  height: 100%;

  .button {
    height: 100%;
    border: none;
    border-radius: 0;
    background-color: transparent;
    color: var(--mantine-color-gray-8);
    font-weight: var(--mantine-font-weight-bold);
    font-size: var(--mantine-font-size-md);
    padding: 0 var(--mantine-spacing-md);

    transform: none;

    &:hover {
      color: var(--mantine-color-gray-9);
      background-color: var(--mantine-color-gray-2);
    }

    &[data-active] {
      color: var(--mantine-color-brand-8);
      background-color: var(--mantine-color-gray-3);
    }

    &[data-active]:hover {
      color: var(--mantine-color-brand-7);
      background-color: var(--mantine-color-gray-2);
    }

    .rightIcon > * {
      width: 1.5rem;
      height: 1.5rem;
    }

    .label {
      height: auto;
      flex-direction: column;
      align-items: flex-end;
      overflow: visible;
    }

    .subLabel {
      font-size: var(--mantine-font-size-sm);
      color: var(--mantine-color-gray-7);
    }

    .subLabelDetail {
      font-size: var(--mantine-font-size-sm);
      color: var(--mantine-color-gray-7);
      font-weight: var(--mantine-font-weight-medium);
    }
  }
}

.buttonColumn {
  gap: calc(var(--mantine-spacing-xs) / 4);
}

.clientBadge {
  background-color: var(--mantine-color-blue-2);
  color: var(--mantine-color-blue-8);
  padding: 0 var(--mantine-spacing-xs);
  font-size: var(--mantine-font-size-xs);
  line-height: 1.25em;
  height: auto;
  margin-left: calc(var(--mantine-spacing-xs) / 2);
}
